'use client'

import { Input } from '@/components/v2/atoms/input/input'
import { Textarea } from '@/components/v2/atoms/textarea/textarea'
import { InputWrapper } from '@/components/v2/molecules/input-wrapper/input-wrapper'
import {
  ImageUploader,
  onChangeImageUploaderType,
} from '@/components/v3/organisms/image-uploader/image-uploader'
import { ErrorCode } from '@/errors/errorCode'
import { ApiImage } from '@/types/Image'
import { UserSelect } from '@/v2/components/molecules/user-select/user-select'
import s from './author-page.form.module.css'
import { Button } from '@/components/v3/atoms/button/button'
import { useEffect, useState } from 'react'
import { useActionState } from 'react'
import { toast } from 'sonner'
import { Prisma, user } from '@prisma/client'
import { validateInstagramUrlRegexp } from '@/utils/regex-expressions'
import { authorPageFormAction } from '@/components/v3/unique/admin/author-page-form/author-page-form.action'

const MAX_BIO_LENGTH = 1000
const MAX_INPUT_LENGTH = 191

type Props = {
  onClose?: () => void
  users?: Pick<user, 'id' | 'email'>[]
  author?: Prisma.author_profileGetPayload<{
    select: {
      user_id: true
      user: true
      job_title: true
      instagram_url: true
      biography: true
      image: {
        select: {
          id: true
          url: true
          alt: true
        }
      }
    }
  }>
}

const AuthorPageForm = ({ users, onClose, author }: Props) => {
  const [image, setImage] = useState<
    Pick<ApiImage, 'id' | 'url' | 'alt'> | undefined
  >({
    id: author?.image.id!,
    url: author?.image.url!,
    alt: author?.image.alt!,
  })
  const [userId, setUserId] = useState<number | undefined>(author?.user_id)
  const [isImageUploading, setIsImageUploading] = useState(false)
  const [state, formAction, isPending] = useActionState(
    async (prevState: any, formData: FormData) => {
      if (image) {
        formData.append('imageId', String(image.id))
      }

      if (userId) {
        formData.append('userId', String(userId))
      }
      return authorPageFormAction(prevState, formData)
    },
    null
  )
  const isButtonDisabled = isPending || isImageUploading

  useEffect(() => {
    if (state?.success) {
      onClose?.()
      toast.success(state?.message)
    } else if (state?.success === false) {
      toast.error(state?.message)
    }
  }, [state?.success])

  const onImageChange = ({
    uploadedImage,
    state,
  }: onChangeImageUploaderType) => {
    setImage(uploadedImage)

    if (state === 'started') {
      setIsImageUploading(true)
    } else {
      setIsImageUploading(false)
    }
  }

  const onUserChange = (value: number) => {
    setUserId(value)
  }

  return (
    <form className={s.form} action={formAction}>
      {
        <InputWrapper label="Select author">
          <UserSelect
            users={author ? [author.user] : users}
            onChange={onUserChange}
            value={userId}
            role="admin"
            disabled={author !== undefined}
          />
        </InputWrapper>
      }
      <InputWrapper label="Author image">
        <ImageUploader
          dataType="author"
          onChange={onImageChange}
          errorCode={ErrorCode.e00129}
          value={image}
          showAlt={false}
        />
      </InputWrapper>

      <InputWrapper label="Job Title">
        <Input
          required
          name="jobTitle"
          defaultValue={state?.formData?.jobTitle || author?.job_title}
          maxLength={MAX_INPUT_LENGTH}
        />
      </InputWrapper>

      <InputWrapper label="Instagram Handle (optional)">
        <Input
          type="url"
          pattern={validateInstagramUrlRegexp}
          name="instagramUrl"
          defaultValue={
            state?.formData?.instagramUrl || author?.instagram_url || ''
          }
          maxLength={MAX_INPUT_LENGTH}
        />
      </InputWrapper>
      <InputWrapper label="Biography">
        <Textarea
          required
          maxLength={MAX_BIO_LENGTH}
          name="biography"
          defaultValue={state?.formData?.biography || author?.biography}
        />
      </InputWrapper>
      <div className={s.buttonWrapper}>
        <Button
          type="submit"
          disabled={isButtonDisabled || !userId || !image?.id}
        >
          Save
        </Button>
        <Button type="button" onClick={onClose} disabled={isButtonDisabled}>
          Close
        </Button>
      </div>
    </form>
  )
}

export default AuthorPageForm
