'use client'

import { api } from '@/api'
import { ErrorCode } from '@/errors/errorCode'
import { ChangeEvent, useState } from 'react'
import s from './image-uploader.module.css'
import cx from 'classnames'
import { Image } from '@/components/v2/atoms/image/image'
import { PROD_URL } from '@/constants'
import { ApiImage } from '@/types/Image'

export type onChangeImageUploaderType = {
  uploadedImage?: Pick<ApiImage, 'id' | 'url' | 'alt'>
  file?: File
  state?: 'started' | 'finished'
}

type Props = {
  dataType: 'article' | 'author'
  targetId?: string
  onChange: (payload: onChangeImageUploaderType) => void
  errorCode: ErrorCode
  onError?: (error: Error) => void
  className?: string
  value?: { url: string; alt: string }
  showAlt?: boolean
}

export function ImageUploader(props: Props) {
  const value = props.value
  const dataType = props.dataType
  const targetId = props.targetId
  const errorCode = props.errorCode
  const className = props.className
  const showAlt = props.showAlt

  const [uploadedImage, setUploadedImage] = useState<ApiImage>()
  const [isUploading, setIsUploading] = useState(false)
  const [altText, setAltText] = useState(value?.alt || '')

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    setIsUploading(true)
    props.onChange({ file, state: 'started' })
    const reader = new FileReader()

    reader.onload = async () => {
      const [err, uploadedImage] = await api.fe.uploads.uploadImagesToS3({
        files: [{ file }],
        dataType,
        targetId,
      })

      if (err) {
        console.log(errorCode, err)
        props.onError?.(err)
        setIsUploading(false)
        return
      }

      setUploadedImage(uploadedImage[0])
      setIsUploading(false)
      props.onChange({
        uploadedImage: { ...uploadedImage[0], alt: altText },
        state: 'finished',
      })
    }

    reader.readAsDataURL(file)
  }

  const handleAltChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newAlt = event.target.value
    setAltText(newAlt)

    if (uploadedImage) {
      props.onChange({ uploadedImage: { ...uploadedImage, alt: newAlt } })
    }
  }

  return (
    <div className={cx(s.wrapper, className)}>
      <div className={s.container}>
        {isUploading && <div className={s.spinner} />}
        <Image
          src={
            uploadedImage?.url ||
            value?.url ||
            `${PROD_URL}/assets/images/placeholder.jpeg`
          }
          alt={altText}
          width={150}
          height={150}
          className={s.preview}
          noFit
        />
        <input
          type="file"
          onChange={handleChange}
          accept=".jpg,.jpeg,.png"
          multiple={false}
          className={s.input}
        />
      </div>

      {showAlt && (
        <div className={s.inputWrapper}>
          <label htmlFor="alt-text" className={s.label}>
            Alt:
          </label>
          <input
            id="alt-text"
            name="alt"
            type="text"
            placeholder="Alternative text"
            value={altText}
            onChange={handleAltChange}
            className={s.altInput}
          />
        </div>
      )}
    </div>
  )
}
