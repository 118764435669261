// TODO: replace with icon not from antd
import SyncOutlined from '@ant-design/icons/SyncOutlined'
import s from './spin-animation.module.css'
import cx from 'classnames'

type Props = {
  style?: React.CSSProperties
  className?: string
}

export const SpinAnimation = (props: Props) => {
  const { style, className } = props
  return (
    <div className={cx(s.wrapper, className)} style={style}>
      {
        //@ts-ignore Antdesign types incompatible with react@19
        <SyncOutlined spin />
      }
    </div>
  )
}
