import { ApiError } from '@/errors/apiError'
import { ApiHandlerResult } from '..'
import { BaseApi } from '../base'
import { Fetcher } from '../fetcher'
import { ApiImage } from '@/types/Image'

type ImageRequestParams = {
  files: { file: File; videoUrl?: string }[]
  targetId?: string
  dataType:
    | 'article'
    | 'recycle'
    | 'supplier'
    | 'profile_picture'
    | 'logo'
    | 'author'
}
export class UploadsApi extends BaseApi {
  constructor(fetcher: Fetcher) {
    super(fetcher)
  }

  public getImageDimensions(
    file: File
  ): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image()
      const url = URL.createObjectURL(file)

      img.onload = () => {
        resolve({ width: img.width, height: img.height })
        URL.revokeObjectURL(url)
      }

      img.onerror = (err) => {
        URL.revokeObjectURL(url)
        reject(err)
      }

      img.src = url
    })
  }

  private replaceDotsWithUnderscores(fileName: string): string {
    return fileName
      .replace(/\.(?=[^.]+$)/, '#TEMP#')
      .replace(/\./g, '_')
      .replace('#TEMP#', '.')
  }

  /**
   * use on the frontend only
   */
  async uploadImagesToS3(
    payload: ImageRequestParams
  ): Promise<ApiHandlerResult<ApiImage[]>> {
    const targetId = payload.targetId
    const dataType = payload.dataType

    const filesData = await Promise.all(
      payload.files.map(async (fileObj) => {
        let width = null
        let height = null

        try {
          const dimensions = await this.getImageDimensions(fileObj.file)
          width = dimensions.width
          height = dimensions.height
        } catch (err) {
          console.warn(`Couldn't get image dimensions: ${fileObj.file.name}`)
        }

        return {
          fileName: this.replaceDotsWithUnderscores(fileObj.file.name),
          fileType: fileObj.file.type,
          videoUrl: fileObj.videoUrl,
          width,
          height,
        }
      })
    )

    try {
      const response = await this.fetcher.post('/uploads/presigned-url', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ filesData, targetId, dataType }),
      })

      const presignedUrlsResponse = (await response.json()) as {
        presignedUrls: {
          fileName: string
          url: string
        }[]
        images: ApiImage[]
      }

      const uploadPromises = presignedUrlsResponse.presignedUrls.map(
        ({ fileName, url }) => {
          const file = payload.files.find(
            (fileObj) => fileObj.file.name === fileName
          )

          if (!file) {
            console.log(`File ${fileName} not found`)
          }

          return fetch(url, {
            method: 'PUT',
            body: file?.file,
          }).then(async (uploadResponse) => {
            if (uploadResponse.ok) {
              console.log(`File ${file?.file.name} uploaded successfully`)
            } else {
              console.log(`File ${file?.file.name} upload failed`)
              console.log(await uploadResponse.text())
            }

            return uploadResponse
          })
        }
      )

      const uploadResults = await Promise.all(uploadPromises)
      const failedRequests = uploadResults.filter((result) => !result.ok)

      if (failedRequests.length > 0) {
        return [new ApiError(failedRequests[0].status, 'Upload failed'), null]
      }

      return [null, presignedUrlsResponse.images]
    } catch (error: any) {
      return [error, null]
    }
  }
}
