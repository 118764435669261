import { XIconNew } from '@/components/svg/x-icon-new/x-icon-new'
import { NavSearch } from '../nav-search/nav-search'
import s from './main-nav-mobile.module.css'
import Link from 'next/link'
import { ArrowRight } from '@/components/svg/arrow-right/arrow-right'
import { NavButton } from '../nav-button/nav-button'
import { UserIcon } from '@/components/svg/user-icon/user-icon'
import { ROUTES } from '@/constants'
import cx from 'classnames'
import { useOnClickOutside, useScrollLock } from 'usehooks-ts'
import { useEffect, useRef, useState } from 'react'
import { useUser } from '@/hooks/use-user'
import { ArrowLeftLong } from '@/components/svg/arrow-left-long/arrow-left-long'
import { MenuItemsType } from '../../../../v2/organisms/navigation/navigation-index/navigation-index'
import {
  getMainCategoryObject,
  getSubcategoryObject,
  splitSubcategories,
} from '../main-nav/main-nav'
import { navItemOnClickGTMEvent } from '@/utils/gtmEvents/navItemOnClickGTMEvent'

type Props = {
  isMenuOpen: boolean
  onOutsideNavClick: () => void
  menuItems: MenuItemsType
}

export const MainNavMobile = ({
  isMenuOpen,
  onOutsideNavClick,
  menuItems,
}: Props) => {
  const { user, isAdmin, isSupplier, isReader, logOut } = useUser()

  const [selectedCategory, setSelectedCategory] = useState('')
  const [subcategoriesEnabled, setSubcategoriesEnabled] = useState(false)

  const mainCategoriesRef = useRef<HTMLDivElement>(null)
  const [mainCategoriesScrollY, setMainCategoriesScrollY] = useState(0)

  const handleCloseMenu = () => {
    setSelectedCategory('')
    setSubcategoriesEnabled(false)
    setMainCategoriesScrollY(0)
    if (mainCategoriesRef.current) {
      mainCategoriesRef.current.scrollTo({
        top: mainCategoriesScrollY,
        behavior: 'instant',
      })
    }
    onOutsideNavClick()
  }

  const refContainer = useRef<HTMLDivElement>(null)
  useOnClickOutside(
    refContainer as React.RefObject<HTMLElement>,
    handleCloseMenu
  )
  useScrollLock({
    autoLock: isMenuOpen,
  })

  const subcategoriesRef = useRef<
    {
      slug: string
      ref: HTMLDivElement
    }[]
  >([])

  const getProfileLink = () => {
    if (isAdmin) {
      return ROUTES.ADMIN.ROOT
    }

    if (isSupplier) {
      return ROUTES.SUPPLIER.SUMMARY
    }

    if (isReader) {
      return ROUTES.CUSTOMER.DASHBOARD
    }

    return ''
  }

  const profileLink = getProfileLink()

  useEffect(() => {
    // save scroll position after back from subcategories
    if (!subcategoriesEnabled && mainCategoriesRef.current) {
      mainCategoriesRef.current.scrollTo({
        top: mainCategoriesScrollY,
        behavior: 'instant',
      })
    }
  }, [mainCategoriesScrollY, subcategoriesEnabled])

  useEffect(() => {
    // start subcategories scroll from top
    if (subcategoriesEnabled) {
      const subcategory = subcategoriesRef.current.find(
        (item) => item.slug === selectedCategory
      )
      if (subcategory) {
        subcategory.ref.scrollTo({
          top: 0,
          behavior: 'instant',
        })
      }
    }
  }, [selectedCategory, subcategoriesEnabled])

  return (
    <div className={cx(s.overlay, [{ [s.open]: isMenuOpen }])}>
      <div
        className={cx(s.container, [{ [s.open]: isMenuOpen }])}
        ref={refContainer}
      >
        <div className={s.topWrapper}>
          {subcategoriesEnabled ? (
            <button
              className={s.back}
              onClick={() => {
                setSubcategoriesEnabled(false)
                setSelectedCategory('')
              }}
            >
              <ArrowLeftLong
                svgProps={{
                  width: 16,
                  height: 16,
                }}
              />{' '}
              Back
            </button>
          ) : (
            <NavSearch onSubmit={handleCloseMenu} />
          )}

          <button className={s.close} onClick={onOutsideNavClick}>
            <XIconNew
              iconColor="#fff"
              svgProps={{
                width: 24,
                height: 24,
                'aria-label': 'Close Menu',
              }}
            />
          </button>
        </div>

        <nav className={s.nav} ref={mainCategoriesRef}>
          <ul
            className={cx(s.navList, [
              {
                [s.close]: subcategoriesEnabled,
              },
            ])}
          >
            {menuItems.map((item) => {
              const mainCatObj = getMainCategoryObject(item.mainItem)

              if (item.subcategories.length) {
                return (
                  <li key={mainCatObj?.slug} className={s.navItem}>
                    <button
                      className={s.navLink}
                      onClick={() => {
                        setMainCategoriesScrollY(
                          mainCategoriesRef?.current?.scrollTop || 0
                        )
                        setSelectedCategory(mainCatObj?.slug || '')
                        setSubcategoriesEnabled(true)
                      }}
                    >
                      {mainCatObj?.name} <ArrowRight />
                    </button>
                  </li>
                )
              }
              return (
                <li
                  key={mainCatObj?.slug}
                  className={s.navItem}
                  onClick={handleCloseMenu}
                >
                  <Link
                    href={`/${mainCatObj?.slug}`}
                    onClick={() => navItemOnClickGTMEvent(mainCatObj?.name)}
                    className={s.navLink}
                  >
                    {mainCatObj?.name}
                  </Link>
                </li>
              )
            })}
          </ul>

          {menuItems
            .filter((item) => item.subcategories.length)
            .map((item) => {
              const mainCatObj = getMainCategoryObject(item.mainItem)
              const subcategories = splitSubcategories(item.subcategories)

              return (
                <div
                  key={`${mainCatObj?.slug}-subcategory`}
                  className={cx(s.navList, s.navListSubcategory, [
                    { [s.open]: selectedCategory === mainCatObj?.slug },
                  ])}
                  ref={(el) => {
                    if (el) {
                      subcategoriesRef.current.push({
                        slug: mainCatObj?.slug || '',
                        ref: el,
                      })
                    }
                  }}
                >
                  <div className={s.subcategoryTitle} onClick={handleCloseMenu}>
                    <Link
                      href={`/${mainCatObj?.slug || ''}`}
                      className={s.subcategoryTitleLink}
                      onClick={() => navItemOnClickGTMEvent(mainCatObj?.name)}
                    >
                      All {mainCatObj?.name}{' '}
                      <ArrowLeftLong
                        svgProps={{
                          style: { transform: 'rotate(180deg)' },
                          width: 16,
                          height: 16,
                        }}
                      />
                    </Link>
                  </div>
                  {subcategories?.map((row, idx) => {
                    const rowTitle = row[0].metadata?.rowTitle
                    return (
                      <ul
                        key={`${mainCatObj?.slug}-subcategory-row-${idx}`}
                        className={cx(s.navList)}
                        style={{ padding: 0 }}
                      >
                        {!!rowTitle && (
                          <li className={cx(s.navItem, s.navSubRowTitle)}>
                            {rowTitle}
                          </li>
                        )}
                        {row.map((subcategory) => {
                          const subcategoryObj = getSubcategoryObject(
                            mainCatObj,
                            subcategory
                          )

                          return (
                            <li
                              key={subcategoryObj?.slug}
                              className={cx(s.navItem, {
                                [s.navItemWithRowTitle]: !!rowTitle,
                              })}
                              onClick={handleCloseMenu}
                            >
                              <Link
                                href={subcategoryObj?.slug || ''}
                                className={s.navLink}
                                onClick={() =>
                                  navItemOnClickGTMEvent(subcategoryObj?.name)
                                }
                              >
                                {subcategoryObj?.name}
                              </Link>
                            </li>
                          )
                        })}
                      </ul>
                    )
                  })}
                </div>
              )
            })}
        </nav>

        <div className={s.bottomWrapper}>
          {user ? (
            <div className={s.bottomLinkWrapper} onClick={handleCloseMenu}>
              <NavButton href={profileLink}>
                <UserIcon /> Your Dashboard
              </NavButton>
              <NavButton
                elementProps={{
                  onClick: logOut,
                }}
                type="secondary"
              >
                Log Out
              </NavButton>
            </div>
          ) : (
            <div className={s.bottomLinkWrapper} onClick={handleCloseMenu}>
              <NavButton href={ROUTES.SIGN_IN} type="secondary">
                <UserIcon iconColor="#fff" /> Log in
              </NavButton>
              <NavButton href={ROUTES.SIGN_UP}>Create Account</NavButton>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
