'use client'

import s from './user-select.module.css'
import { api } from '@/api'
import { Select } from '@/components/v2/atoms/select/select'
import { ErrorCode } from '@/errors/errorCode'
import { ICurrentUser } from '@/utils/auth/auth'
import { useEffect, useState } from 'react'
import cx from 'classnames'

type UserOptionItem = {
  value: number
  label: string
}

type Props = {
  role: 'admin' // | 'reader' | 'supplier' currently disabling reader/supplier as it'd require pagination
  onChange: (value: UserOptionItem['value']) => void
  value?: UserOptionItem['value']
  disabled?: boolean
  users?: Pick<ICurrentUser, 'id' | 'email'>[]
}

export const UserSelect = (props: Props) => {
  const [options, setOptions] = useState<UserOptionItem[]>([])
  const [err, setErr] = useState<string>()

  useEffect(() => {
    if (!props.users) {
      api.fe.user.getUsers({ role: props.role }).then(([err, users]) => {
        if (err) {
          console.log(ErrorCode.e00088, err)
          setErr('Something went wrong, please contact support')
          return
        }

        const options = users.results.map((user) => ({
          value: user.id,
          label: user.email,
        }))

        setOptions(options)
      })
    } else {
      const options = props.users.map((user) => ({
        value: user.id,
        label: user.email,
      }))

      setOptions(options)
    }
  }, [])

  const valueOrLabel =
    options.find((option) => option.value === props.value)?.label ||
    'Select Author'

  return (
    <Select
      className={cx(s.select, { [s.disabled]: props.disabled })}
      label={valueOrLabel}
      value={props.value}
      onChange={props.onChange}
      disabled={!!err || props.disabled}
      options={options}
    />
  )
}
