import { useModal } from '@/hooks/use-modal'
import AuthorPageForm from '@/components/v3/unique/admin/author-page-form/author-page-form'
import s from './author-page-form-modal.module.css'

export const AuthorPageFormModal = () => {
  const modal = useModal()

  return (
    <div className={s.modal}>
      <AuthorPageForm
        author={modal.options.modalProps.author}
        users={modal.options.modalProps.users}
        onClose={() => modal.close()}
      />
    </div>
  )
}
